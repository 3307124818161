import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import {rcTheme} from "./config/theme";
import {
    RcAppAuthTypes,
    RcReactApp
} from "@rocketcodeit/react-components";
import {applicationMenu} from "./config/applicationMenu";
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import './index.css';

initializeIcons();

ReactDOM.render(
    <AppEntry />,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

function AppEntry() {

    return (
        <RcReactApp theme={rcTheme} menu={applicationMenu} authType={RcAppAuthTypes.rocket} />
    )
}
