import {IRcTheme} from "@rocketcodeit/react-components";

export const rcTheme: IRcTheme = {
    fluentUi: {
        palette: {
            themePrimary: '#cc0100',
            themeDark: '#cc0100',
            themeSecondary: '#cc0100',
        },
        semanticColors: {
            primaryButtonBackground: '#cc0100',
            primaryButtonBackgroundHovered: '#9f1918',
            buttonTextHovered: '#000',
            linkHovered: '#329b0d',
        }
    },
    rc: {
        logo: {
            path: "/logo.png",
            color: "#b61d1c",
        },
        navigationUI: {
            position: 'header'
        },
        headerBar: {
            disabled: true,
            backgroundColor: "#cc0100",
        },
        authRocket: {
            logo: {
                path: "/logo.png"
            },
        }
    }
};
