import { RcApplicationMenu } from "@rocketcodeit/react-components";
import React from "react";

export const applicationMenu: RcApplicationMenu[] = [
    {
        id: "books",
        title: "Libri",
        routerLink: "/",
        hasIndex: true,
        visible: true,
        isPublic: false,
        component: React.lazy(() => import("../pages/Books")),
        children: [{
            id: "book-detail",
            title: "Book detail",
            routerLink: "/:id",
            hasIndex: false,
            visible: false,
            isPublic: false,
            component: React.lazy(() => import("../pages/Books/BookDetail/BookDetail")),
            children: [{
                id: "designer",
                title: "Designer",
                routerLink: "/reader",
                hasIndex: false,
                visible: false,
                isPublic: false,
                component: React.lazy(() => import("../pages/Books/components/Designer/Preview/Preview")),
                layout: 'blank'
            }, {
                id: "designer",
                title: "Designer",
                routerLink: "/chapters/:chapterId/designer",
                hasIndex: false,
                visible: false,
                isPublic: false,
                component: React.lazy(() => import("../pages/Books/components/Designer/Designer")),
            }, {
                id: "designer",
                title: "Designer",
                routerLink: "/chapters/:chapterId/designer/reader",
                hasIndex: false,
                visible: false,
                isPublic: false,
                component: React.lazy(() => import("../pages/Books/components/Designer/Preview/Preview")),
                layout: 'blank'
            }]
        }]
    },
    {
        id: "settings",
        title: "Impostazioni",
        routerLink: "/settings",
        hasIndex: false,
        visible: true,
        isPublic: false,
        component: React.lazy(() => import("../pages/Settings/CustomStyles")),
        children: [{
            id: "custom-style",
            title: "Stili personalizzati",
            routerLink: "/customStyles",
            hasIndex: true,
            visible: true,
            isPublic: false,
            component: React.lazy(() => import("../pages/Settings/CustomStyles")),
        }]
    }
]
